import { Link, useLocation, useParams } from "react-router-dom";
import WithLayout from "../../components/Layout/Layout";
import LocalNews from "../../components/HomeComp/LocalNewsHomeComp";
import TopStoriesDetailsCom from "../../components/HomeComp/TopStoriesDetailsCom/TopStoriesDetailsCom";
import { useEffect, useState } from "react";
import TopStoriesDetailsMobileCom from "../../components/HomeComp/TopStoriesDetailsCom/TopStoriesDetailsMobileCom";
import { useDispatch, useSelector } from "react-redux";
import {
  getPublicLatestPost,
  morePost,
} from "../../redux/actions/publicAction";
import CategoryPolls from "../../components/CategoryPolls/CategoryPolls";
import moment from "moment";
import SecHead from "../../components/SectionHeader/SecHead";
import MorePost from "../../components/HomeComp/MorePostHomeComp";
import LatestNewsBottom from "../../components/commonComponenets/LatestNewsBottom";
import SideShimmer from "../../common/SideShimmer";
import { Helmet } from "react-helmet-async";

function LatestNewsDetailsId() {
  const { id, slug } = useParams();
  const location = useLocation();
  // console.log("id",id);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [isMobile, setIsMobile] = useState(
    window.matchMedia("(max-width: 768px)").matches
  );
  const { latestPost, morepostdata } = useSelector(
    (state) => state.publicReducer
  );

  useEffect(() => {
    dispatch(morePost(3)).finally(() => setLoading(false));
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.matchMedia("(max-width: 768px)").matches);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const isVerified = localStorage.getItem("accessToken");
  // bradcumb
  const pathSegments = location.pathname.split("/").filter(Boolean);
  // pathSegments.pop();
  const breadCumb = [
    { id: 1, name: isVerified ? "For You" : "News", link: "/" },
    ...pathSegments.map((segment, index) => {
      // Construct link for each segment
      const link = `/${pathSegments.slice(0, index + 1).join("/")}`;
      // console.log("link", link);

      return { id: index + 2, name: formatBreadcrumbName(segment), link };
    }),
  ];
  function formatBreadcrumbName(name) {
    return name
      .replace(/-/g, " ")
      .toLowerCase()
      .replace(/\b\w/g, (char) => char.toUpperCase());
  }
  const createSlug = (title) =>
    title
      .toLowerCase()
      .replace(/\s+/g, "-")
      .replace(/[^\w-]+/g, "");
  function cleanString(str) {
    return str.replace(/[^a-zA-Z0-9-_]/g, "");
  }
  return (
    <>
      {/* <Helmet>
        <title>{"User"}'s Post</title>
        <meta
          property="og:title"
          content={"Pennsylvania President Polls 2024"}
        />
        <meta
          property="og:description"
          content={
            "The upcoming US Presidential election is likely to be decided by the outcomes in six pivotal swing states: Georgia, Arizona, Michigan, Pennsylvania, Nevada, and Wisconsin. Together, these states account for roughly 240 million eligible voters in this year's election."
          }
        />
        <meta
          property="og:image"
          content={
            "https://oar.blr1.cdn.digitaloceanspaces.com/PROD/images/17298517217410_pen_webp.webp"
          }
        />
        <meta
          property="og:url"
          content={
            "https://pp.extcons.xyz/latest-news/pennsylvania-president-polls-2024"
          }
        />
        <meta property="og:type" content="article" />
      </Helmet> */}
      <div className="bg_home  bg-[#F7F8FA] md:px-40 flex flex-col pb-14">
        <div className="flex-[2] ">
          {isMobile ? (
            <div className="flex flex-col  gap-5 ">
              <div className=" flex flex-col ">
                <TopStoriesDetailsMobileCom postDetailsid={slug} />
              </div>
            </div>
          ) : (
            <>
              <div className="py-4">
                {breadCumb.map((item, index) => (
                  <span
                    key={item.id}
                    className={`text-[10px] font-medium ${
                      index === breadCumb.length - 1 ? "" : "opacity-60"
                    }`}
                  >
                    {index > 0 && " > "}
                    <Link to={item.link} className="breadcrumb-link">
                      {item.name}
                    </Link>
                  </span>
                ))}
              </div>
              <div className="flex min-[1040px]:flex-row flex-col  gap-5 mb-5 ">
                <div className="flex-[2] flex flex-col gap-5 lg:w-3/5">
                  <TopStoriesDetailsCom postDetailsid={slug} />
                </div>
                <div className="flex-1 flex flex-col gap-5">
                  <div className="bg-white w-full p-3 md:p-5 px-4 rounded-lg sticky top-[3rem]">
                    <SecHead title={"More Post"} redirectLink="/more-posts" />
                    {!loading ? (
                      <div className=" ">
                        {Array.isArray(morepostdata) &&
                          morepostdata?.map((item, index) => (
                            <Link
                              key={item?.id}
                              to={`/more-posts/${item?.slug}`}
                            >
                              <div
                                key={item?.id}
                                className={`flex flex-row items-start  gap-4 md:gap-4 py-2 border-b border-gray-300   ${
                                  index === morepostdata?.length - 1
                                    ? "border-none"
                                    : "border-b"
                                } justify-between`}
                              >
                                <div className="flex flex-col mt-2 md:mt-0 md:pt-1 md:gap-2 md:opacity-90">
                                  <CategoryPolls
                                    title={item?.category?.name}
                                    id={item?.category?.id}
                                    img={item?.category?.icon}
                                  />
                                  <span
                                    className=" text-[14px] text-[#1A1A1A] leading-[18px] open-sans-600  tracking-tight"
                                    style={{ lineHeight: 1.4 }}
                                  >
                                    {item?.title}
                                  </span>

                                  <span className="date_shown mt-4">
                                    {" "}
                                    {moment(item?.updatedAt).format(
                                      "MMMM D, YYYY"
                                    )}
                                  </span>
                                </div>

                                <img
                                  src={item?.file ?? ""}
                                  alt={item?.fileId}
                                  className="  w-20 mt-2 md:w-20 h-auto object-cover rounded-md "
                                />
                              </div>
                            </Link>
                          ))}
                      </div>
                    ) : (
                      <SideShimmer />
                    )}
                  </div>
                </div>
              </div>
            </>
          )}

          {/* latest post common componenet */}
          <LatestNewsBottom />
        </div>
      </div>
    </>
  );
}
LatestNewsDetailsId.Layout = WithLayout;
export default LatestNewsDetailsId;
