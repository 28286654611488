import { POLLS_AVERAGE_DATA, POLLING_TABLE_LIST, ELECTORAL_VOTES, DELHI_TABLE_LIST, INDIA_POLL_TYPE_LIST, DELHI_POLL_YEARS_LIST, VOTER_CATEGORY_LIST, GET_PARTY_LIST, ADD_PREDICTION_DATA, PREDICTION_LISTING_DATA, RESET_RESPONSE, PREDICTION_DATA_BY_ID, UPDATE_PREDICTION_DATA, SELECT_PREDICTION_LISTING_DATA, PREVIOUS_PREDICTION_LISTING_DATA, ADD_PREDICTION_POST_DATA, PREDICTION_POST_LISTING_DATA, PREDICTION_POST_LIKE_UNLIKE, PREDICTION_POST_FOLLOW_UNFOLLOW, PREDICTION_POST_COMMENT, PREDICTION_POST_DETAIL_DATA, COPY_BEST_POLLSTER, GET_SEGMENT_MODIFIOCATION_TYPE, POLLSTER_PREDICTION_LISTING_DATA, COPIED_POLLSTER_PREDICTION_DATA, FOLLOWED_PREDICTION_POST_LISTING_DATA } from "../actions/type";

// import { CATEGORY_DETAIL, FOR_YOU_DATA, GET_BACK_TO_DETAILS, GET_NAVBAR_MENULIST, GET_PUBLIC_LACAL_NEWS, GET_PUBLIC_POST, GET_PUBLIC_POST_DETAILS, GLOBAL_SEARCH, LATEST_POST, MORE_POST, PICK_FOR_YOU_DATA, POST_COMMENT, STORE_COMMENT, YOUR_TOPIC_DATA } from "../actions/type";
const initialState = {
    pollingList: [],
    pollsAverageResponse: [],
    IndianPollsresponse: [],
    VoterCategoryListResponse: [],
    delhiPollsYearsResponse: [],
    PartyListResponse: [],
    AddPredictionResponse: {},
    AddPredictionPostResponse: {},
    UpdatePredictionResponse: {},
    PredictionListingResponse: [],
    PolsterPredictionListingResponse: [],
    CopyBestPosterResponse: [],
    SegemntModificationTypeResponse: [],
    PredictionPostListingResponse: [],
    FollowedPredictionPostListingResponse: [],
    PredictionPostFollowUnfollowResponse: [],
    PredictionPostLikeUnlikeResponse: [],
    PredictionPostCommentResponse: [],
    PredictionPostDetailResponse: [],
    PredictionDetailResponse: [],
    SelectPredictionResponse: [],
    PreviousPredictionData: [],
    CopiedPollsterPredictionResponse: [],
    // publicPostDetails: {},
    // publicLocalNews:[],
    // menuList:[],
    // latestPost:[],
    // morepostdata:{},
    // navbarlistid:"",
    // globalsearch:"",
    // forYou:[],
    // yourTopic:[],
    // pickforyou:[],
    // postcommentres:"",
    // storedComment:"",
    // getbacktodetails:""
}

const pollingReducer = (state = initialState, action) => {
    switch (action.type) {
        case POLLING_TABLE_LIST: return { ...state, pollingList: action.payload?.data }
        case DELHI_TABLE_LIST: return { ...state, delhiElectionData: action.payload?.data }
        case DELHI_POLL_YEARS_LIST: return { ...state, delhiPollsYearsResponse: action.payload?.data }
        case POLLS_AVERAGE_DATA: return { ...state, pollsAverageResponse: action.payload?.data }
        case ELECTORAL_VOTES: return { ...state, electoralVotesResponse: action.payload?.data }
        case INDIA_POLL_TYPE_LIST: return { ...state, IndianPollsresponse: action.payload?.data }
        case VOTER_CATEGORY_LIST: return { ...state, VoterCategoryListResponse: action.payload?.data }
        case COPY_BEST_POLLSTER: return { ...state, CopyBestPosterResponse: action.payload?.data }
        case GET_SEGMENT_MODIFIOCATION_TYPE: return { ...state, SegemntModificationTypeResponse: action.payload?.data }
        case GET_PARTY_LIST: return { ...state, PartyListResponse: action.payload?.data }
        case ADD_PREDICTION_DATA: return { ...state, AddPredictionResponse: action.payload }
        case ADD_PREDICTION_POST_DATA: return { ...state, AddPredictionPostResponse: action.payload }
        case UPDATE_PREDICTION_DATA: return { ...state, UpdatePredictionResponse: action.payload }
        case PREDICTION_LISTING_DATA: return { ...state, PredictionListingResponse: action.payload?.data }
        case POLLSTER_PREDICTION_LISTING_DATA: return { ...state, PolsterPredictionListingResponse: action.payload?.data }
        case PREDICTION_POST_LISTING_DATA: return { ...state, PredictionPostListingResponse: action.payload?.data }
        case FOLLOWED_PREDICTION_POST_LISTING_DATA: return { ...state, FollowedPredictionPostListingResponse: action.payload?.data }
        case PREDICTION_POST_FOLLOW_UNFOLLOW: return { ...state, PredictionPostFollowUnfollowResponse: action.payload?.data }
        case PREDICTION_POST_LIKE_UNLIKE: return { ...state, PredictionPostLikeUnlikeResponse: action.payload?.data }
        case PREDICTION_POST_COMMENT: return { ...state, PredictionPostCommentResponse: action.payload?.data }
        case PREDICTION_POST_DETAIL_DATA: return { ...state, PredictionPostDetailResponse: action.payload?.data }
        case PREDICTION_DATA_BY_ID: return { ...state, PredictionDetailResponse: action.payload?.data }
        case SELECT_PREDICTION_LISTING_DATA: return { ...state, SelectPredictionResponse: action.payload?.data }
        case PREVIOUS_PREDICTION_LISTING_DATA: return { ...state, PreviousPredictionData: action.payload?.data }
        case COPIED_POLLSTER_PREDICTION_DATA: return { ...state, CopiedPollsterPredictionResponse: action.payload?.data }


        // case GET_PUBLIC_POST_DETAILS: return { ...state, publicPostDetails: action.payload?.data }
        // case GET_PUBLIC_LACAL_NEWS:return{...state,publicLocalNews:action.payload?.data}
        // case LATEST_POST:return{...state,latestPost:action?.payload?.data}
        // case MORE_POST:return{...state,morepostdata:action?.payload?.data}
        // case GET_NAVBAR_MENULIST:return{...state,menuList:action.payload}
        // case CATEGORY_DETAIL:return{...state,navbarlistid:action?.payload?.data}
        // case GLOBAL_SEARCH:return{...state,globalsearch:action?.payload?.data}
        // case FOR_YOU_DATA:return{...state,forYou:action?.payload?.data}
        // case YOUR_TOPIC_DATA:return{...state,yourTopic:action?.payload?.data}
        // case PICK_FOR_YOU_DATA:return{...state,pickforyou:action?.payload?.data}
        // case POST_COMMENT:return{...state,postcommentres:action?.payload?.msg}
        // case STORE_COMMENT:return{...state,storedComment:action?.payload}
        // case GET_BACK_TO_DETAILS:return{...state,getbacktodetails:action?.payload}
        case RESET_RESPONSE: return { ...state, AddPredictionResponse: null, UpdatePredictionResponse: null, PreviousPredictionData: null }
        default: return state;
    }
}
export default pollingReducer;