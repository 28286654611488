// import api from "../api";
// import { GET_PUBLIC_POST_DETAILS, GET_PUBLIC_POST,GET_NAVBAR_MENULIST,GET_PUBLIC_LACAL_NEWS,POST_COMMENT,LATEST_POST,MORE_POST, CATEGORY_DETAIL, GLOBAL_SEARCH, FOR_YOU_DATA, YOUR_TOPIC_DATA, PICK_FOR_YOU_DATA, SHOW_TOSTER, STORE_COMMENT, GET_BACK_TO_DETAILS } from "./type";

import { useNavigate } from "react-router-dom";
import api from "../api";
import { ELECTORAL_VOTES, POLLING_TABLE_LIST, POLLS_AVERAGE_DATA, DELHI_TABLE_LIST, INDIA_POLL_TYPE_LIST, DELHI_POLL_YEARS_LIST, VOTER_CATEGORY_LIST, GET_PARTY_LIST, ADD_PREDICTION_DATA, PREDICTION_LISTING_DATA, RESET_RESPONSE, PREDICTION_DATA_BY_ID, UPDATE_PREDICTION_DATA, SELECT_PREDICTION_LISTING_DATA, PREVIOUS_PREDICTION_LISTING_DATA, ADD_PREDICTION_POST_DATA, PREDICTION_POST_LISTING_DATA, PREDICTION_POST_LIKE_UNLIKE, PREDICTION_POST_FOLLOW_UNFOLLOW, PREDICTION_POST_COMMENT, PREDICTION_POST_DETAIL_DATA, COPY_BEST_POLLSTER, GET_SEGMENT_MODIFIOCATION_TYPE, POLLSTER_PREDICTION_LISTING_DATA, COPIED_POLLSTER_PREDICTION_DATA, FOLLOWED_PREDICTION_POST_LISTING_DATA } from "./type";

export const getPollingTableList = (payload, candidateName) => async (dispatch) => {
  try {
    const response = await api.get(`/user/polls/view?${payload}&candidateName=${candidateName}`);
    if (response?.status === 200) {
      dispatch({ type: POLLING_TABLE_LIST, payload: response?.data });
    } else if (response?.status === 401) {
      localStorage.removeItem("accessToken")
    }
    else {
    }
  } catch (error) {

  }
};

export const getIndiaPolltypes = () => async (dispatch) => {
  try {
    const response = await api.get(`/user/get-poll-types/view/india`);
    if (response.status === 200) {
      dispatch({ type: INDIA_POLL_TYPE_LIST, payload: response?.data })
    }
    else if (response.status === 401) {
      localStorage.removeItem("accessToken");
    }
  }
  catch (error) {
    // console.error(error);

  }
}

// Delhi Polls Actions 

export const getDelhiPolls = (payload) => async (dispatch) => {
  try {
    const response = await api.get(`/user/indian-delhi-polls/view?${payload ? payload : ""}`);
    if (response?.status === 200) {
      dispatch({ type: DELHI_TABLE_LIST, payload: response?.data });
    }
    else if (response?.status === 401) {
      localStorage.removeItem("accessToken")

    }
    else {
      // console.log("")
    }
  }
  catch (error) {
    if (error?.status === 401) {
      localStorage.removeItem("accessToken")

    }
    // console.log(error);

  }
}
export const getDelhiPollsYears = () => async (dispatch) => {
  try {
    const response = await api.get(`/user/indian-delhi-polls/year-list`);
    if (response?.status === 200) {
      dispatch({ type: DELHI_POLL_YEARS_LIST, payload: response?.data });
    }
    else if (response?.status === 401) {
      localStorage.removeItem("accessToken")

    }
    else {
      // console.log("")
    }
  }
  catch (error) {
    if (error?.status === 401) {
      localStorage.removeItem("accessToken")

    }
    // console.log(error);

  }
}
export const getPollsAverage = () => async (dispatch) => {
  try {
    const response = await api.get(`/user/polls/calculate-poll-average`);
    if (response?.status === 200) {
      dispatch({ type: POLLS_AVERAGE_DATA, payload: response?.data });
    }
    else if (response?.status === 401) {
      localStorage.removeItem("accessToken")
    }
    else {
    }
  } catch (error) {

  }
};
export const getElectoralVotes = () => async (dispatch) => {
  try {
    const response = await api.get(`/user/electoral-votes/view`);
    if (response?.status === 200) {
      dispatch({ type: ELECTORAL_VOTES, payload: response?.data });
    }
    else if (response?.status === 401) {
      localStorage.removeItem("accessToken")
    }
    else {
    }
  } catch (error) {

  }
};

// India Prediction Actions 

export const getVoterCategoryList = (payload) => async (dispatch) => {
  try {
    const response = await api.post(`/user/voter-category/view`, payload);
    if (response?.status === 200) {
      dispatch({ type: VOTER_CATEGORY_LIST, payload: response?.data });
    }
    else if (response?.status === 401) {
      localStorage.removeItem("accessToken")
    }
    else {
      // console.log("")
    }
  }
  catch (error) {
    if (error?.status === 401) {
      localStorage.removeItem("accessToken")

    }
    // console.log(error);

  }
}
export const getCopyPolster = (payload) => async (dispatch) => {
  try {
    const response = await api.get(`/user/pollster-category/view/${payload}`);
    if (response?.status === 200) {
      dispatch({ type: COPY_BEST_POLLSTER, payload: response?.data });
    }
    else if (response?.status === 401) {
      localStorage.removeItem("accessToken")
    }
    else {
      // console.log("")
    }
  }
  catch (error) {
    if (error?.status === 401) {
      localStorage.removeItem("accessToken")

    }
    // console.log(error);

  }
}
export const getPartyList = (payload) => async (dispatch) => {
  try {
    const response = await api.get(`/user/prediction-header/view/${payload}`);
    if (response?.status === 200) {
      dispatch({ type: GET_PARTY_LIST, payload: response?.data });
    }
    else if (response?.status === 401) {
      localStorage.removeItem("accessToken")

    }
    else {
      // console.log("")
    }
  }
  catch (error) {
    if (error?.status === 401) {
      localStorage.removeItem("accessToken")

    }
    // console.log(error);

  }
}
export const getSegmentModificationType = (payload) => async (dispatch) => {
  try {
    const response = await api.get(`/user/prediction-modification/view`);
    if (response?.status === 200) {
      dispatch({ type: GET_SEGMENT_MODIFIOCATION_TYPE, payload: response?.data });
    }
    else if (response?.status === 401) {
      localStorage.removeItem("accessToken")

    }
    else {
      // console.log("")
    }
  }
  catch (error) {
    if (error?.status === 401) {
      localStorage.removeItem("accessToken")

    }
    // console.log(error);

  }
}
export const addUserPrediction = (payload) => async (dispatch) => {
  try {
    const response = await api.post(`/user/user-prediction/add`, payload);
    if (response?.status === 201) {
      dispatch({ type: ADD_PREDICTION_DATA, payload: response?.data });
      // console.log("logging from action ", response?.data);

    }
    else if (response?.status === 401) {
      localStorage.removeItem("accessToken")

    }
    else {
      // console.log("")
    }
  }
  catch (error) {
    if (error?.status === 401) {
      localStorage.removeItem("accessToken")

    }
    // console.log(error);

  }
}
export const addPredictionPost = (payload) => async (dispatch) => {
  try {
    const response = await api.post(`/user/user-post/add`, payload);
    if (response?.status === 201) {
      dispatch({ type: ADD_PREDICTION_POST_DATA, payload: response?.data });
    }
    else if (response?.status === 401) {
      localStorage.removeItem("accessToken")

    }
    else {
      // console.log("")
    }
  }
  catch (error) {
    if (error?.status === 401) {
      localStorage.removeItem("accessToken");
    }
  }
}
export const updateUserPrediction = (payload) => async (dispatch) => {
  try {
    const response = await api.put(`/user/user-prediction/edit`, payload);
    if (response?.status === 200) {
      dispatch({ type: UPDATE_PREDICTION_DATA, payload: response?.data });
      console.log("logging from action ", response?.data);

    }
    else if (response?.status === 401) {
      localStorage.removeItem("accessToken")

    }
    else {
      // console.log("")
    }
  }
  catch (error) {
    if (error?.status === 401) {
      localStorage.removeItem("accessToken")

    }
    // console.log(error);

  }
}

export const getUserPrediction = (payload) => async (dispatch) => {
  try {
    const response = await api.get(`/user/user-prediction/view`);
    if (response?.status === 200) {
      dispatch({ type: PREDICTION_LISTING_DATA, payload: response?.data });
    }
    else if (response?.status === 401) {
      localStorage.removeItem("accessToken")

    }
    else {
    }
  }
  catch (error) {
    if (error?.status === 401) {
      localStorage.removeItem("accessToken")

    }
  }
}
export const getPollsterPrediction = (payload) => async (dispatch) => {
  try {
    const response = await api.get(`/user/poll-analysis/view/${payload}`);
    if (response?.status === 200) {
      dispatch({ type: POLLSTER_PREDICTION_LISTING_DATA, payload: response?.data });
    }
    else if (response?.status === 401) {
      localStorage.removeItem("accessToken")

    }
    else {
    }
  }
  catch (error) {
    if (error?.status === 401) {
      localStorage.removeItem("accessToken")

    }
  }
}
export const getFollowedPredictionPost = (payload) => async (dispatch) => {
  try {
    let filterQuery = "";

    if (Array.isArray(payload.payload) && payload.payload.length > 0) {
      filterQuery = payload.payload.map(filter => `filter=${filter}`).join("&");
    }
    let search = payload.search ? `search=${payload.search}` : ""
    let finalQuery = ""
    if (search && filterQuery) {
      finalQuery = `?${search}&${filterQuery}`
    }
    else if (search) {
      finalQuery = `?${search}`
    }
    else if (filterQuery) {
      finalQuery = `?${filterQuery}`
    }

    const response = await api.get(`/user/my-followed-prediction/view${finalQuery}`);
    if (response?.status === 200) {
      dispatch({ type: FOLLOWED_PREDICTION_POST_LISTING_DATA, payload: response?.data });
    }
    else if (response?.status === 401) {
      localStorage.removeItem("accessToken")

    }
    else {
    }
  }
  catch (error) {
    if (error?.status === 401) {
      localStorage.removeItem("accessToken")

    }
  }
}
export const getUserPredictionPost = (payload) => async (dispatch) => {
  try {

    let filterQuery = "";

    if (Array.isArray(payload.payload) && payload.payload.length > 0) {
      filterQuery = payload.payload.map(filter => `filter=${filter}`).join("&");
    }
    let search = payload.search ? `search=${payload.search}` : ""
    let finalQuery = ""
    if (search && filterQuery) {
      finalQuery = `?${search}&${filterQuery}`
    }
    else if (search) {
      finalQuery = `?${search}`
    }
    else if (filterQuery) {
      finalQuery = `?${filterQuery}`
    }

    const response = await api.get(`/user/user-post/view${finalQuery}`);
    if (response?.status === 200) {
      dispatch({ type: PREDICTION_POST_LISTING_DATA, payload: response?.data });
    }
    else if (response?.status === 401) {
      localStorage.removeItem("accessToken")

    }
    else {
    }
  }
  catch (error) {
    if (error?.status === 401) {
      localStorage.removeItem("accessToken")

    }
  }
}
export const addUserPredictionPostLikeUnlike = (payload) => async (dispatch) => {
  try {
    const response = await api.post(`/user/post/like-unlike`, payload);
    if (response?.status === 200) {
      dispatch({ type: PREDICTION_POST_LIKE_UNLIKE, payload: response?.data });
    }
    else if (response?.status === 401) {
      localStorage.removeItem("accessToken")

    }
    else {
    }
  }
  catch (error) {
    if (error?.status === 401) {
      localStorage.removeItem("accessToken")

    }
  }
}
export const addUserPredictionPostComment = (payload) => async (dispatch) => {
  try {
    const response = await api.post(`user/post-comment/add`, payload);
    if (response?.status === 200) {
      dispatch({ type: PREDICTION_POST_COMMENT, payload: response?.data });
    }
    else if (response?.status === 401) {
      localStorage.removeItem("accessToken")

    }
    else {
    }
  }
  catch (error) {
    if (error?.status === 401) {
      localStorage.removeItem("accessToken")

    }
  }
}
export const getUserPredictionPostDetails = (payload) => async (dispatch) => {
  try {
    const response = await api.get(`/user/user-post/view/${payload}`);
    if (response?.status === 200) {
      dispatch({ type: PREDICTION_POST_DETAIL_DATA, payload: response?.data });
    }
    else if (response?.status === 401) {
      localStorage.removeItem("accessToken")

    }
    else {
    }
  }
  catch (error) {
    if (error?.status === 401) {
      localStorage.removeItem("accessToken")

    }
  }
}
export const addUserPredictionPostFollowUnfollow = (payload) => async (dispatch) => {
  try {
    const response = await api.post(`/user/follow-unfollow/add`, payload);
    if (response?.status === 200) {
      dispatch({ type: PREDICTION_POST_FOLLOW_UNFOLLOW, payload: response?.data });
    }
    else if (response?.status === 401) {
      localStorage.removeItem("accessToken")

    }
    else {
    }
  }
  catch (error) {
    if (error?.status === 401) {
      localStorage.removeItem("accessToken")

    }
  }
}
export const getPreviousUserPrediction = (payload) => async (dispatch) => {
  try {
    const response = await api.get(`/user/user-prediction/previous`);
    if (response?.status === 200) {
      dispatch({ type: PREVIOUS_PREDICTION_LISTING_DATA, payload: response?.data });
    }
    else if (response?.status === 401) {
      localStorage.removeItem("accessToken")

    }
    else {
    }
  }
  catch (error) {
    if (error?.status === 401) {
      localStorage.removeItem("accessToken")

    }
  }
}
export const getCopiedPollsterPrediction = (payload) => async (dispatch) => {
  try {
    const response = await api.post(`/user/user-prediction/best-and-average`, payload);
    if (response?.status === 200) {
      dispatch({ type: COPIED_POLLSTER_PREDICTION_DATA, payload: response?.data });
    }
    else if (response?.status === 401) {
      localStorage.removeItem("accessToken")

    }
    else {
    }
  }
  catch (error) {
    if (error?.status === 401) {
      localStorage.removeItem("accessToken")

    }
  }
}
export const getUserPredictionById = (payload) => async (dispatch) => {
  try {
    let filterQuery = "";

    if (Array.isArray(payload.payload) && payload.payload.length > 0) {
      filterQuery = payload.payload.map(filter => `filter=${filter}`).join("&");
    }

    const response = await api.get(
      `/user/user-prediction/view/${payload.id}${filterQuery ? `?${filterQuery}` : ""}`
    );

    if (response?.status === 200) {
      dispatch({ type: PREDICTION_DATA_BY_ID, payload: response?.data });
    } else if (response?.status === 401) {
      localStorage.removeItem("accessToken");
    }
  } catch (error) {
    if (error?.response?.status === 401) {
      localStorage.removeItem("accessToken");
    }
  }
};

export const geSelectPrediction = (payload) => async (dispatch) => {
  try {
    const response = await api.get(`/user/prediction-selection/view`);
    if (response?.status === 200) {
      dispatch({ type: SELECT_PREDICTION_LISTING_DATA, payload: response?.data });
    }
    else if (response?.status === 401) {
      localStorage.removeItem("accessToken")

    }
    else {
    }
  }
  catch (error) {
    if (error?.status === 401) {
      localStorage.removeItem("accessToken")

    }
  }
}
export const resetPollFields = () => ({
  type: RESET_RESPONSE
})


