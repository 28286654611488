import React, { useEffect, useState } from "react";
import PredictionContext from "./predictionContext";

const PredictionProvider = ({ children }) => {
  const [prediction, setPrediction] = useState({
    newPrediction: false,
    selectCountry: null,
    electionTypeData: { state: null, type: null },
    prediction: null,
    freshPredict: false,
  });

  return (
    <PredictionContext.Provider value={{ prediction, setPrediction }}>
      {children}
    </PredictionContext.Provider>
  );
};

export default PredictionProvider;
