import React, { useState } from "react";
import UserContext from "./userContecxt";

const UserContextProvider = ({ children }) => {
  const [userContextData, setUserContextData] = useState({
    name: "",
    email: "",
    id: "",
    profileUrl: "",
  });
  return (
    <UserContext.Provider value={{ userContextData, setUserContextData }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserContextProvider;
