import React from "react";
import oppnothingFoundsmsg from "../../assets/icons/nothingFound.svg";
import { useNavigate } from "react-router-dom";

const NothingFound = () => {
  const navigator = useNavigate();
  return (
    <div className="bg-white h-[60vh]">
      <div className="flex flex-col justify-center items-center h-[50vh]">
        <div className="">
          <img
            src={oppnothingFoundsmsg}
            alt="nothing found"
            className="w-24 h-24 object-contain"
          />
        </div>
        <p className="text-2xl font-bold ">Nothing Found</p>
        <span
          className="my-3 transsition-all px-3 py-2 rounded-md  bg-red-600 hover:text-red-600 hover:bg-white border-2 border-red-600 text-white font-bold active:scale-95  duration-300"
          role="button"
          onClick={() => navigator("/")}
        >
          Go to Home
        </span>
      </div>
    </div>
  );
};

export default NothingFound;
