import React, { useCallback, useEffect, useMemo, useState } from "react";
import userFollow from "../../assets/icons/userFollow.png";
import { Helmet } from "react-helmet-async";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import {
  addUserPredictionPostComment,
  addUserPredictionPostFollowUnfollow,
  addUserPredictionPostLikeUnlike,
  getUserPredictionPostDetails,
} from "../../redux/actions/pollingAction";
import likeIcon from "../../assets/icons/likeOutline.png";
import darkLikeIcon from "../../assets/icons/Like Icon.png";
import darkfilledLikeIcon from "../../assets/icons/Like Filled Black.png";
import CommentIcon from "../../assets/icons/commentOutline.png";
import shareIcon from "../../assets/icons/shareOutline.png";
import whitefilledLikeIcon from "../../assets/icons/Like Filled White.png";
import profilePlaceholder from "../../assets/icons/profile_placeholder.png";
import { useNavigate, useParams } from "react-router-dom";

const UserPollPredictionDetailComp = () => {
  const { PredictionPostDetailResponse } = useSelector(
    (state) => state.pollWeb
  );

  const params = useParams();
  const [render, setRender] = useState(false);

  const [commentData, setCommentData] = useState({
    id: null,
    comment: "",
  });

  const [createdTime, setCreatedTime] = useState(0);
  const navigate = useNavigate();
  const isVerified = localStorage.getItem("accessToken");
  useEffect(() => {
    if (!isVerified) {
      navigate("/login");
    }
  }, []);

  useEffect(() => {
    if (PredictionPostDetailResponse) {
      const createdAt = new Date(
        PredictionPostDetailResponse?.result?.[0]?.updatedAt
      );
      const now = new Date();
      const diffMs = now - createdAt;
      const diffMinutes = Math.floor(diffMs / (1000 * 60)); // Convert to minutes
      const diffHours = Math.floor(diffMinutes / 60);
      const diffDays = Math.floor(diffHours / 24);

      let formattedTime;
      if (diffMinutes < 60) {
        formattedTime = `${diffMinutes} minutes ago`;
      } else if (diffHours < 24) {
        formattedTime = `${diffHours} hours ago`;
      } else {
        formattedTime = `${diffDays} day${diffDays > 1 ? "s" : ""} ago`;
      }

      setCreatedTime(formattedTime);
    }
  }, [PredictionPostDetailResponse]); // Add time as a dependency

  useEffect(
    function setPostId() {
      if (params?.id) {
        setCommentData((prev) => ({
          ...prev,
          id: params?.id,
        }));
      }
    },
    [params?.id]
  );

  const dispatch = useDispatch();
  useEffect(
    function getUserDetailAction() {
      dispatch(getUserPredictionPostDetails(params?.id));
    },
    [params?.id, render]
  );

  const postDetail = useMemo(
    () => PredictionPostDetailResponse,
    [PredictionPostDetailResponse]
  );

  const [localIsLiked, setLocalIsLiked] = useState();

  const [localIsFollow, setLocalIsFollow] = useState();
  const [localLikes, setLocalLikes] = useState(0);

  useEffect(() => {
    if (PredictionPostDetailResponse) {
      setLocalLikes(PredictionPostDetailResponse?.likes);
      setLocalIsFollow(PredictionPostDetailResponse?.isFollow);
      setLocalIsLiked(PredictionPostDetailResponse?.isLiked);
    }
  }, [PredictionPostDetailResponse]);

  const transformedData = postDetail?.result || [];

  const handleLikeUnlike = (id) => {
    setLocalIsLiked((prev) => !prev);
    // setLocalLikes((prev) => (localIsLiked ? prev - 1 : prev + 1));
    setLocalLikes((prev) =>
      prev !== undefined ? prev + (localIsLiked ? -1 : 1) : 0
    );
    dispatch(addUserPredictionPostLikeUnlike({ id: id }));
    setRender((prev) => !prev);
  };

  console.log("postDetail", postDetail?.userPostDetails?.user?.profilePicture);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setCommentData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const getaccount = useSelector(
    (state) => state.privateReducer.getaccount,
    shallowEqual
  );

  const handleFollowToggle = () => {
    if (params?.id) {
      setLocalIsFollow(!localIsFollow);
      const payload = {
        followingId: PredictionPostDetailResponse?.userPostDetails?.userId,
      };
      dispatch(addUserPredictionPostFollowUnfollow(payload));
    }
  };

  const pageUrl = `https://pp.extcons.xyz/prediction/predictions_post/${params?.id}`;

  const renderLegend = useCallback(() => {
    return (
      <div style={{ textAlign: "center", marginTop: 10 }}>
        <div style={{ display: "flex", justifyContent: "center", gap: 15 }}>
          {transformedData.map((entry, index) => (
            <div
              key={`legend-${index}`}
              style={{ display: "flex", alignItems: "center", gap: 5 }}
            >
              <span
                style={{
                  width: 12,
                  height: 12,
                  backgroundColor: entry?.["party.color_code"],
                  display: "inline-block",
                }}
              />
              <span className="capitalize">
                {entry?.["party.abbreviation"]}
              </span>
            </div>
          ))}
        </div>
      </div>
    );
  }, [transformedData]);
  const [isCopied, setIsCopied] = useState(false);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const handleShareClick = () => {
    setIsShareModalOpen((prev) => !prev);
  };
  const handleCopy = () => {
    navigator.clipboard.writeText(pageUrl);
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
      setIsShareModalOpen(false);
    }, [2000]);
  };

  const handleAddPredictioncomment = () => {
    if (commentData?.comment.trim() !== "") {
      dispatch(addUserPredictionPostComment(commentData)).then(() => {
        setCommentData((prev) => ({
          ...prev,
          comment: "",
        }));
        dispatch(getUserPredictionPostDetails(params?.id));
      });
    } else {
      alert("Please add a comment before submitting.");
    }
  };

  return (
    <div className="bg_home bg-white px-4 min-[955px]:px-40 flex flex-col pb-6 md:py-5">
      <div>
        <Helmet>
          <meta property="og:title" content="Delhi election Prediction" />
          <meta
            property="og:description"
            content={PredictionPostDetailResponse?.userPostDetails?.description}
          />
          <meta
            property="og:image"
            content="https://www.statista.com/graphic/1/1096434/india-delhi-legislative-election-results-vote-share-by-leading-party.jpg"
          />
          <meta property="og:url" content={pageUrl} />
          <meta property="og:type" content="article" />
        </Helmet>
        <div className="border w-full min-h-[60vh] rounded-3xl overflow-hidden">
          {/* card Header  */}
          <div className="flex justify-between items-center p-3 bg-[#1A1A1A] text-white">
            <div className="flex gap-2">
              <img
                src={
                  postDetail?.userPostDetails?.user?.profilePicture ||
                  `https://static.vecteezy.com/system/resources/thumbnails/003/337/584/small/default-avatar-photo-placeholder-profile-icon-vector.jpg`
                }
                alt="profile icone"
                className="rounded-full size-[45px] object-cover"
              />
              <div className="">
                <p className="text-[16px] capitalize">
                  {postDetail?.userPostDetails?.user?.firstName ?? "user"}
                </p>
                {createdTime && <p className="text-[12px]">{createdTime}</p>}
              </div>
            </div>
            <div
              className={
                getaccount?.id ===
                  PredictionPostDetailResponse?.userPostDetails?.userId &&
                "hidden"
              }
            >
              <button
                className="bg-white rounded-3xl flex gap-2 items-center px-3 py-2 active:scale-90 transition-all"
                onClick={handleFollowToggle}
              >
                <img src={userFollow} alt="userImg" width={15} />
                <p className="text-[#1A1A1A] text-[12px]">
                  {localIsFollow ? "Unfollow" : "Follow"}
                </p>
              </button>
            </div>
          </div>
          {/* card body  */}
          <div className="bg-[#F7F8FA]">
            <p className="text-[20px] text-[#707070] px-3 pt-2">
              {PredictionPostDetailResponse?.userPostDetails?.description}
            </p>
            {/* bar chart here   */}
            <div className="">
              <ResponsiveContainer width="100%" height={400}>
                <BarChart
                  data={transformedData}
                  margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Legend content={renderLegend} />
                  <Bar dataKey="averageResults" barSize={60}>
                    {transformedData?.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={entry?.["party.color_code"]}
                      />
                    ))}
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
          {/* card footer  */}
          <div className="bg-[#DCDFE3] flex justify-between items-center  p-5">
            <div className="flex gap-3">
              <p className="flex items-center">
                <img
                  src={localIsLiked ? darkfilledLikeIcon : darkLikeIcon}
                  alt="likeicon"
                  className="size-[15px] mx-2"
                />
                {localIsLiked && localLikes > 1
                  ? `You and ${localLikes - 1} other(s)`
                  : localIsLiked && localLikes < 2
                  ? `You liked`
                  : localLikes}
              </p>
              {console.log("localLikes", localLikes)}
              <p>
                <span className="mx-1">
                  {PredictionPostDetailResponse?.commentsCount}
                </span>
                <span>Comments </span>
              </p>
              <p>
                <span className="mx-1">0</span>
                <span>Share</span>
              </p>
            </div>
            <div className="flex gap-2">
              <button
                className="bg-[#1A1A1A] rounded-3xl flex gap-2 items-center px-3 py-2 active:scale-90 transition-all"
                onClick={() => handleLikeUnlike(params?.id)}
              >
                <img
                  src={localIsLiked ? whitefilledLikeIcon : likeIcon}
                  alt="userImg"
                  width={15}
                />
                <p className="text-white text-[12px]">Like</p>
              </button>
              <button className="bg-[#1A1A1A] rounded-3xl flex gap-2 items-center px-3 py-2 active:scale-90 transition-all">
                <img src={CommentIcon} alt="userImg" width={15} />
                <p className="text-white text-[12px]">Comment</p>
              </button>
              <button
                className="bg-[#1A1A1A] rounded-3xl flex gap-2 items-center px-3 py-2 active:scale-90 transition-all"
                onClick={handleShareClick}
              >
                <img src={shareIcon} alt="userImg" width={15} />
                <p className="text-white text-[12px]">Share</p>
              </button>
              {isShareModalOpen && (
                <div className="absolute top-[85%] mt-2 right-28 bg-white shadow-lg rounded-lg p-2 w-48 border">
                  <button
                    className="w-full text-left py-1 px-2 hover:bg-gray-100 rounded active:scale-95 transition-all"
                    onClick={handleCopy}
                  >
                    Copy Link
                    <p className={`text-green-500 ${!isCopied && "hidden"}`}>
                      Link Copied!
                    </p>
                  </button>
                  <a
                    href={`https://twitter.com/intent/tweet?url=${pageUrl}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="block w-full text-left py-1 px-2 hover:bg-gray-100 rounded active:scale-95 transition-all"
                  >
                    Share on Twitter
                  </a>
                  <a
                    href={`https://api.whatsapp.com/send?text=${pageUrl}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="block w-full text-left py-1 px-2 hover:bg-gray-100 rounded active:scale-95 transition-all"
                  >
                    Share on WhatsApp
                  </a>
                </div>
              )}
            </div>
          </div>

          {/* comments section  */}
          <div
            className={`w-full  transition-all overflow-hidden duration-700`}
          >
            <div className="mx-7 my-2">
              {postDetail?.comments?.length > 0 && (
                <p className="font-semibold text-[12px]">
                  All Comments ({postDetail?.comments?.length})
                </p>
              )}
              <div className=" overflow-y-auto w-[90%] h-[40vh] my-3 mx-auto p-5 scrollbar-mob">
                {postDetail?.comments?.length > 0 ? (
                  <div className="border rounded-xl p-5 space-y-2 divide-y-2">
                    {postDetail?.comments?.map((entry) => {
                      return (
                        <div
                          className="flex justify-start items-center gap-4 py-1"
                          key={entry?.id || entry?.text}
                        >
                          <div className="">
                            <img
                              src={
                                entry?.creator?.profilePicture ||
                                profilePlaceholder
                              }
                              alt="profile placeholder"
                              width={50}
                            />
                          </div>
                          <div className="">
                            <div className="flex items-center gap-2 font-semibold text-[14px]">
                              <p className="text-[16px] capitalize">
                                {entry?.creator?.firstName ?? "User"}
                              </p>
                              {/* <p className=" text-[12px] text-[#B1BBC8]">
                        1 day ago
                      </p> */}
                            </div>
                            <p>{entry?.text}</p>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <div className="text-xl font-bold text-center">
                    No Comments Yet
                  </div>
                )}
              </div>
            </div>
            <div className="my-5 w-[80%] mx-auto">
              <input
                type="text"
                onChange={handleChange}
                value={commentData?.comment}
                name="comment"
                className="bg-[#F7F8FA] px-5 pt-2 pb-5 rounded-xl w-full border-gray-200 focus:border focus:outline-none placeholder:text-[12px]"
                placeholder="Write a comment..."
              />
              <button
                disabled={commentData?.comment === ""}
                className={`rounded-3xl  my-2 text-white px-5 py-1 float-end active:scale-95 transition-all  ${
                  commentData?.comment === "" ? "bg-gray-300" : "bg-[#E4002B]"
                }`}
                onClick={handleAddPredictioncomment}
              >
                Publish
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserPollPredictionDetailComp;
