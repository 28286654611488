import { useNavigate } from "react-router-dom";
import api from "../api";
import {
  GET_FOLLOW_INTEREST,
  POST_INTEREST,
  GET_FOLLOWING_DATA,
  POST_FOLLOWING_DATA,
  SELECTED_INTERESTED_DATA,
  GET_TAGS_DATA,
  POST_TOPICS_TAGS_DATA,
  GET_SEARCH_TAGS_DATA,
  GET_MODEL_INTEREST_DATA,
  GET_TOP_STORIES,
  ACCOUNT_UPDATE,
  GET_ACCOUNT,
  GET_TRANDING,
  SELECTED_TAGS_ISBOREDED,
  GET_ALL_PLANS,
  SELECTED_PLAN,
  POST_SUBSCRIPTION,
  GET_VERIFIED,
  CREATE_PAYMENT,
  INDIA_POLL_RESULT,
  GET_PREDICTION,
  GET_ELECTION_TYPE,
  GET_STATE_BY_ID,
  GET_STATE_BY_POLL_DATA
} from "./type";



export const topStories = (payload) => async (dispatch) => {
  try {
    const response = await api.get(`/user/top-stories?rows=${payload ?? ""}`);
    if (response?.status === 200) {
      dispatch({ type: GET_TOP_STORIES, payload: response?.data });
    }

    else {
    }
  } catch (error) {
    if (error?.status === 401) {
      localStorage.removeItem("accessToken")
      localStorage.removeItem("email")
      alert("Session expired. Please log in again.")
      window.location.href = "/login"
    }
  }
};
export const trandingData = (payload) => async (dispatch) => {
  try {
    const response = await api.get(`/user/trending?rows=${payload ?? ""}`);
    if (response?.status === 200) {
      dispatch({ type: GET_TRANDING, payload: response?.data });
    }

    else {
    }
  } catch (error) {
    if (error?.status === 401) {
      localStorage.removeItem("accessToken")
      localStorage.removeItem("email")
      alert("Session expired. Please log in again.")
      window.location.href = "/login"
    }
  }
};
export const getFollowYourInterest = () => async (dispatch) => {
  try {
    const response = await api.get(`/user/topics`);
    // console.log("getFollowYourInterest", response);
    if (response?.status === 200) {
      dispatch({ type: GET_FOLLOW_INTEREST, payload: response?.data });
    }

    else {

    }
  } catch (error) {
    if (error?.status === 401) {
      localStorage.removeItem("accessToken")
      localStorage.removeItem("email")
      alert("Session expired. Please log in again.")
      window.location.href = "/login"
    }
    // dispatch({
    //     type: SHOW_TOSTER,
    //     payload: { type: "error", message: error?.response?.data?.msg },
    // });
  }
};
export const postInterest = (payload) => async (dispatch) => {
  // console.log("postInterestpayload",payload);

  try {
    const response = await api.post("/user/add-interest", {
      interests: payload,
    });
    if (response?.status === 200) {
      dispatch({ type: POST_INTEREST, payload: response?.data });
      // dispatch({
      //     type: SHOW_TOSTER,
      //     payload: { type: "success", message: response?.data?.msg },
      // });

      // if(localStorage.getItem(""))
      // navigate("/followyourinterest")
    }

    else {
      // dispatch({
      //     type: SHOW_TOSTER,
      //     payload: { type: "error", message: response?.data?.msg },
      // });
    }
    // window.location.href = "/following";
  } catch (error) {
    if (error?.status === 401) {
      localStorage.removeItem("accessToken")
      localStorage.removeItem("email")
      alert("Session expired. Please log in again.")
      window.location.href = "/login"
    }
    // console.log("postInteresterror",error);
    // dispatch({
    //     type: SHOW_TOSTER,
    //     payload: { type: "error", message: error?.response?.data?.msg },
    // });
  }
};

export const getFollowingData = () => async (dispatch) => {
  try {
    const response = await api.get(`/user/get-all-interests`);
    if (response?.status === 200) {
      dispatch({ type: GET_FOLLOWING_DATA, payload: response?.data });
      // dispatch({
      //     type: SHOW_TOSTER,
      //     payload: { type: "success", message: response?.data?.msg },
      // });
    }
    else {
    }
  } catch (error) {
    if (error?.status === 401) {
      localStorage.removeItem("accessToken")
      localStorage.removeItem("email")
      alert("Session expired. Please log in again.")
      window.location.href = "/login"
    }
    // dispatch({
    //     type: SHOW_TOSTER,
    //     payload: { type: "error", message: error?.response?.data?.msg },
    // });
  }
};
export const getPrediction = () => async (dispatch) => {
  try {
    const response = await api.get(`/user/prediction/view`);
    if (response?.status === 200) {
      dispatch({ type: GET_PREDICTION, payload: response?.data });
    }
    else {
    }
  } catch (error) {
    if (error?.status === 401) {
      localStorage.removeItem("accessToken")
      localStorage.removeItem("email")
      alert("Session expired. Please log in again.")
      window.location.href = "/login"
    }
    // dispatch({
    //     type: SHOW_TOSTER,
    //     payload: { type: "error", message: error?.response?.data?.msg },
    // });
  }
};

export const getElectionType = (payload) => async (dispatch) => {
  try {
    const response = await api.get(`/user/get-poll-types/view/${payload}`);
    if (response?.status === 200) {
      dispatch({ type: GET_ELECTION_TYPE, payload: response?.data });
    }
    else {
    }
  } catch (error) {
    if (error?.status === 401) {
      localStorage.removeItem("accessToken")
      localStorage.removeItem("email")
      alert("Session expired. Please log in again.")
      window.location.href = "/login"
    }
    // dispatch({
    //     type: SHOW_TOSTER,
    //     payload: { type: "error", message: error?.response?.data?.msg },
    // });
  }
};
export const getStateById = (payload) => async (dispatch) => {
  try {
    const response = await api.get(`/user/state/list/${payload}`);
    if (response?.status === 200) {
      dispatch({ type: GET_STATE_BY_ID, payload: response?.data });
    }
    else {
    }
  } catch (error) {
    if (error?.status === 401) {
      localStorage.removeItem("accessToken")
      localStorage.removeItem("email")
      alert("Session expired. Please log in again.")
      window.location.href = "/login"
    }
    // dispatch({
    //     type: SHOW_TOSTER,
    //     payload: { type: "error", message: error?.response?.data?.msg },
    // });
  }
};
export const getStateByPollData = (payload) => async (dispatch) => {
  try {
    const response = await api.get(`/user/state/view/${payload}`);
    if (response?.status === 200) {
      dispatch({ type: GET_STATE_BY_POLL_DATA, payload: response?.data });
    }
    else {
    }
  } catch (error) {
    if (error?.status === 401) {
      localStorage.removeItem("accessToken")
      localStorage.removeItem("email")
      alert("Session expired. Please log in again.")
      window.location.href = "/login"
    }
    // dispatch({
    //     type: SHOW_TOSTER,
    //     payload: { type: "error", message: error?.response?.data?.msg },
    // });
  }
};

export const followingDataPost = (payload) => async (dispatch) => {
  try {
    const response = await api.post("/user/add-interest", {
      interests: payload,
    });
    if (response?.status === 200) {
      dispatch({ type: POST_FOLLOWING_DATA, payload: response?.data });
      // dispatch({
      //     type: SHOW_TOSTER,
      //     payload: { type: "success", message: response?.data?.msg },
      // });
    }
    else {

      // dispatch({
      //     type: SHOW_TOSTER,
      //     payload: { type: "error", message: response?.data?.msg },
      // });
    }
    // window.location.href="/following";
  } catch (error) {

    if (error?.status === 401) {
      localStorage.removeItem("accessToken")
      localStorage.removeItem("email")
      alert("Session expired. Please log in again.")
      window.location.href = "/login"
    }
    // console.log("postInteresterror",error);
    // dispatch({
    //     type: SHOW_TOSTER,
    //     payload: { type: "error", message: error?.response?.data?.msg },
    // });
  }
};

export const getSelectedInterest = (payload) => async (dispatch) => {
  // console.log("selectedInterestpayload", payload);
  try {
    const response = await api.get(`/user/get-interest/${payload}`);
    if (response?.status === 200) {
      dispatch({ type: SELECTED_INTERESTED_DATA, payload: response?.data });
    }
    else {
      // dispatch({
      //     type: SHOW_TOSTER,
      //     payload: { type: "error", message: response?.data?.msg },
      // });
    }
  } catch (error) {
    if (error?.status === 401) {
      localStorage.removeItem("accessToken")
      localStorage.removeItem("email")
      alert("Session expired. Please log in again.")
      window.location.href = "/login"
    }
    // dispatch({
    //     type: SHOW_TOSTER,
    //     payload: { type: "error", message: error?.response?.data?.msg },
    // });
  }
};
export const getIndiaPollResults = (payload) => async (dispatch) => {
  try {
    const response = await api.get(`/user/indian-delhi-polls/results?${payload ? payload : ""}`);
    if (response?.status === 200) {
      dispatch({ type: INDIA_POLL_RESULT, payload: response?.data });
    }
    else {
      // dispatch({
      //     type: SHOW_TOSTER,
      //     payload: { type: "error", message: response?.data?.msg },
      // });
    }
  } catch (error) {
    if (error?.status === 401) {
      localStorage.removeItem("accessToken")
      localStorage.removeItem("email")
      alert("Session expired. Please log in again.")
      window.location.href = "/login"
    }
    // dispatch({
    //     type: SHOW_TOSTER,
    //     payload: { type: "error", message: error?.response?.data?.msg },
    // });
  }
};
export const getHashTags = (payload) => async (dispatch) => {
  try {
    const response = await api.get(`/user/get-tag/${payload}`);
    if (response?.status === 200) {
      dispatch({ type: GET_TAGS_DATA, payload: response?.data });
      // dispatch({
      //     type: SHOW_TOSTER,
      //     payload: { type: "success", message: response?.data?.msg },
      // });
    }
    else {
    }
  } catch (error) {
    if (error?.status === 401) {
      localStorage.removeItem("accessToken")
      localStorage.removeItem("email")
      alert("Session expired. Please log in again.")
      window.location.href = "/login"
    }
    // dispatch({
    //     type: SHOW_TOSTER,
    //     payload: { type: "error", message: error?.response?.data?.msg },
    // });
  }
};

export const sendSelectedTags = (payload) => async (dispatch) => {
  // console.log("sendSelectedTagspayload", payload);
  try {
    const response = await api.post("/user/add-tags/", payload);
    if (response?.status === 200) {
      dispatch({ type: POST_TOPICS_TAGS_DATA, payload: response?.data });
      // dispatch({
      //     type: SHOW_TOSTER,
      //     payload: { type: "success", message: response?.data?.msg },
      // });
    }
    else {
      // dispatch({
      //     type: SHOW_TOSTER,
      //     payload: { type: "error", message: response?.data?.msg },
      // });
    }
  } catch (error) {
    if (error?.status === 401) {
      localStorage.removeItem("accessToken")
      localStorage.removeItem("email")
      alert("Session expired. Please log in again.")
      window.location.href = "/login"
    }
    // dispatch({
    //     type: SHOW_TOSTER,
    //     payload: { type: "error", message: error?.response?.data?.msg },
    // });
  }
};

export const searchTagData = (id, value) => async (dispatch) => {
  // console.log("searchTagDatapayload", id, "value", value);

  try {
    const response = await api.get(`/user/get-tag/${id}?search=${value}`);
    if (response?.status === 200) {
      dispatch({ type: GET_SEARCH_TAGS_DATA, payload: response?.data });
      // dispatch({
      //     type: SHOW_TOSTER,
      //     payload: { type: "success", message: response?.data?.msg },
      // });
    }
    else {
    }
  } catch (error) {
    if (error?.status === 401) {
      localStorage.removeItem("accessToken")
      localStorage.removeItem("email")
      alert("Session expired. Please log in again.")
      window.location.href = "/login"
    }
    // dispatch({
    //     type: SHOW_TOSTER,
    //     payload: { type: "error", message: error?.response?.data?.msg },
    // });
  }
};
export const modelshowInterest = () => async (dispatch) => {
  try {
    const response = await api.get(`/user/selected-topics`);
    if (response?.status === 200) {
      dispatch({ type: GET_MODEL_INTEREST_DATA, payload: response?.data });
      // dispatch({
      //     type: SHOW_TOSTER,
      //     payload: { type: "success", message: response?.data?.msg },
      // });
    }
    else {
    }
  } catch (error) {
    if (error?.status === 401) {
      localStorage.removeItem("accessToken")
      localStorage.removeItem("email")
      alert("Session expired. Please log in again.")
      window.location.href = "/login"
    }
    // dispatch({
    //     type: SHOW_TOSTER,
    //     payload: { type: "error", message: error?.response?.data?.msg },
    // });
  }
};
export const modelshowTopic = () => async (dispatch) => {
  // try {
  //     const response = await api.get(`/user/selected-topics`)
  //     if (response?.status === 200) {
  //     dispatch({ type: GET_MODEL_INTEREST_DATA, payload: response?.data });
  //         dispatch({
  //             type: SHOW_TOSTER,
  //             payload: { type: "success", message: response?.data?.msg },
  //         });
  //     } else {
  //     }
  // } catch (error) {
  //     dispatch({
  //         type: SHOW_TOSTER,
  //         payload: { type: "error", message: error?.response?.data?.msg },
  //     });
  // }
};
export const accountUpdate = (payload) => async (dispatch) => {
  try {
    const response = await api.put(`/user/edit-profile`, payload);
    if (response?.status === 200) {
      dispatch({ type: ACCOUNT_UPDATE, payload: response?.data });
      // dispatch({ type: GET_ACCOUNT, payload: response?.data });
      // dispatch({
      //     type: SHOW_TOSTER,
      //     payload: { type: "success", message: response?.data?.msg },
      // });
    }
    else {
    }
  } catch (error) {
    if (error?.status === 401) {
      localStorage.removeItem("accessToken")
      localStorage.removeItem("email")
      alert("Session expired. Please log in again.")
      window.location.href = "/login"
    }
    // dispatch({
    //     type: SHOW_TOSTER,
    //     payload: { type: "error", message: error?.response?.data?.msg },
    // });
  }
};
export const getAccountDetails = () => async (dispatch) => {
  // /user/get-profile
  try {
    const response = await api.get(`/user/get-profile`);
    // console.log("getAccountDetails", response);

    if (response?.status === 200) {
      // console.log("getAccountDetails",response);

      dispatch({ type: GET_ACCOUNT, payload: response?.data });
      // dispatch({
      //     type: SHOW_TOSTER,
      //     payload: { type: "success", message: response?.data?.msg },
      // });
    }

    else {
      // console.log(response, "else hitted condition");

    }
  } catch (error) {

    // dispatch({
    //     type: SHOW_TOSTER,
    //     payload: { type: "error", message: error?.response?.data?.msg },
    // });
    console.log(error, "caught error");
    if (error?.response?.status === 401) {
      localStorage.removeItem("accessToken")
      localStorage.removeItem("email")
      alert("Session expired. Please log in again.")
      window.location.href = "/login"
    }
  }
};
export const selectedTags = (payload) => async (dispatch) => {
  try {
    const response = await api.get(`/user/selected-tags/${payload}`);
    // console.log("selectedTags",response);

    if (response?.status === 200) {
      // console.log("getAccountDetails",response);

      dispatch({ type: SELECTED_TAGS_ISBOREDED, payload: response?.data });
      // dispatch({
      //     type: SHOW_TOSTER,
      //     payload: { type: "success", message: response?.data?.msg },
      // });
    }

    else {
    }
  } catch (error) {
    if (error?.status === 401) {
      localStorage.removeItem("accessToken")
      localStorage.removeItem("email")
      alert("Session expired. Please log in again.")
      window.location.href = "/login"
    }
    // dispatch({
    //     type: SHOW_TOSTER,
    //     payload: { type: "error", message: error?.response?.data?.msg },
    // });
  }
};
export const getAllPlan = () => async (dispatch) => {
  try {
    const response = await api.get(`/user/plan/view`);
    // console.log("selectedTags",response);

    if (response?.status === 200) {
      dispatch({ type: GET_ALL_PLANS, payload: response?.data });
    } else {
    }
  } catch (error) {
    if (error?.status === 401) {
      localStorage.removeItem("accessToken")
      localStorage.removeItem("email")
      alert("Session expired. Please log in again.")
      window.location.href = "/login"
    }
    // dispatch({
    //     type: SHOW_TOSTER,
    //     payload: { type: "error", message: error?.response?.data?.msg },
    // });
  }
};
export const selectPlan = (payload) => async (dispatch) => {
  try {
    const response = await api.get(`/user/plan/view/${payload}`);
    // console.log("selectedTags",response);

    if (response?.status === 200) {
      dispatch({ type: SELECTED_PLAN, payload: response?.data });
    }
    else {
    }
  } catch (error) {
    if (error?.status === 401) {
      localStorage.removeItem("accessToken")
      localStorage.removeItem("email")
      alert("Session expired. Please log in again.")
      window.location.href = "/login"
    }
    // dispatch({
    //     type: SHOW_TOSTER,
    //     payload: { type: "error", message: error?.response?.data?.msg },
    // });
  }
};
export const postSubscription = (payload) => async (dispatch) => {
  try {
    const response = await api.post(`/user/subscription/add`, {
      planId: payload,
    });
    // console.log("selectedTags",response);

    if (response?.status === 200) {
      dispatch({ type: POST_SUBSCRIPTION, payload: response?.data });
    }
    else {
    }
  } catch (error) {
    if (error?.status === 401) {
      localStorage.removeItem("accessToken")
      localStorage.removeItem("email")
      alert("Session expired. Please log in again.")
      window.location.href = "/login"
    }
  }
};
// export const createPaymentIntent=(payload)=>async(dispatch)=>{
//     console.log("createPaymentIntentpayload",payload);

//     try {
//         const response = await api.post(`/create-payment-intent`,payload)
//         console.log("paymentresponse",response);

//         if (response?.status === 200) {

//         dispatch({ type: CREATE_PAYMENT, payload: response?.data });

//         } else {

//         }
//     } catch (error) {

//     }
// }
export const getPayment = (payload) => async (dispatch) => {
  // console.log("getPayment", payload);

  try {
    const response = await api.post(`/user/payment/verify`, {
      payment_id: payload,
    });

    if (response?.status === 200) {
      //   console.log("respons e ", response?.data?.data?.isSuccess);

      dispatch({
        type: GET_VERIFIED,
        payload: response?.data?.data,
      });
    }
    else {
      // Handle non-200 status if needed
    }
  } catch (error) {
    if (error?.status === 401) {
      localStorage.removeItem("accessToken")
      localStorage.removeItem("email")
      alert("Session expired. Please log in again.")
      window.location.href = "/login"
    }
    console.error("Error verifying payment:", error);
    // Handle error (optional)
  }
};
