import React, { useEffect, useState } from "react";
import CategoryPolls from "../CategoryPolls/CategoryPolls";
import loader from "../../assets/loader.gif";
import SecHead from "../SectionHeader/SecHead";
import { useDispatch, useSelector } from "react-redux";
import { getPublicLocalNews } from "../../redux/actions/publicAction";
import moment from "moment";
import SideShimmer from "../../common/SideShimmer";
import { Link } from "react-router-dom";

const LocalNewsHomeComp = () => {
  const dispatch = useDispatch();
  const { publicLocalNews } = useSelector((state) => state.publicReducer);
  const isVerified = localStorage.getItem("accessToken");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    dispatch(getPublicLocalNews(3)).finally(() => setLoading(false));
  }, []);
  const createSlug = (title) =>
    title
      .toLowerCase()
      .replace(/\s+/g, "-")
      .replace(/[^\w-]+/g, "");
  function cleanString(str) {
    return str.replace(/[^a-zA-Z0-9-_]/g, "");
  }
  return (
    <div className="bg-white w-full p-3 md:p-5 px-4 rounded-lg">
      <SecHead title={"Local News"} redirectLink="/local-news" />
      {!loading && publicLocalNews ? (
        <div>
          {Array.isArray(publicLocalNews) &&
            publicLocalNews?.map((item, index) => (
              <Link to={isVerified ? `/local-news/${item?.slug}` : "/login"}>
                <div
                  key={item?.id}
                  className={`flex flex-row items-start  gap-4 md:gap-4 py-2 border-gray-300   ${
                    index === publicLocalNews.length - 1
                      ? "border-none"
                      : "border-b"
                  } justify-between`}
                  style={{
                    minHeight: "4rem",
                  }}
                >
                  <div
                    className="flex flex-col mt-2 md:mt-0 md:pt-1 md:gap-2 md:opacity-90"
                    style={{
                      minHeight: "3rem",
                    }}
                  >
                    <CategoryPolls
                      title={item?.category?.name || "country"}
                      id={item?.category?.id}
                      img={item?.category?.icon}
                    />
                    <span
                      className=" text-[16px] text-gray-800 leading-[18px] font-semibold tracking-normal "
                      style={{ lineHeight: 1.4 }}
                    >
                      {item?.title}
                    </span>

                    <span className="date_shown mt-4">
                      {" "}
                      {moment(item?.updatedAt).format("MMMM D, YYYY")}
                    </span>
                  </div>

                  <img
                    src={item?.file ?? ""}
                    alt={item?.title || "image"}
                    className=" mt-2 md:w-20 h-auto object-cover rounded-md "
                    style={{
                      width: "5rem",
                      height: "auto",
                    }}
                  />
                </div>
              </Link>
            ))}
        </div>
      ) : (
        <SideShimmer />
      )}
    </div>
  );
};

export default LocalNewsHomeComp;
