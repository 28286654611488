import { combineReducers } from "redux";
import authReducer from "./authReducer";
import toasterReducer from "./toasterReducer";
import publicReducer from "./publicReducer";
import privateApiReducer from "./privateApiReducer";
import pollingReducer from "./pollingReducer";

const appReducers = combineReducers({

  auth: authReducer,
  toasterData: toasterReducer,
  publicReducer: publicReducer,
  privateReducer: privateApiReducer,
  pollWeb: pollingReducer,

});


const rootReducer = (state, action) => {

  return appReducers(state, action);
};
export default rootReducer;